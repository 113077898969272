<template>
  <div class="animated fadeIn">
    <b-card  :title="$t('message.customerByDay') ">
      <b-form @submit.prevent="searchFn">
        <div class="row">
          <div class="col-sm-6 col-md-6 col-lg-2 my-1">
            {{ $t("message.year") }} :
            <br />
            <b-form-select v-model="timeSel" :options="language== 'th'? timeOptTH: timeOptEN" v-on:change="selectTime" />
          </div>

          <div class="col-sm-6 col-md-6 col-lg-4 my-1">
            {{ $t("message.machineIdFarm") }} :
            <br />
            <multi-list-select
              :list="machineData"
              option-value="machineId"
              option-text="name"
              :selected-items="selectedMachine"
              :placeholder="$t('message.all')"
              @select="onSelectMachine"
            ></multi-list-select>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-4 my-1">
            {{ $t("message.selectDateTime") }} :
            <br />
            <date-time-picker v-model="searchData.datetime"></date-time-picker>
          </div>

          <div class="col-sm-6 col-md-6 col-lg-2 my-3">
            <b-btn class="col-sm-12 col-md-12 ml-12 mr-3 my-1" variant="primary" type="submit">
              <i class="fa fa-search"></i>
              &nbsp;{{ $t("message.search") }}
            </b-btn>
          </div>
        </div>
        <br />
      </b-form>

      <div class="row" v-if="chartOptions_bar_customer.xaxis.categories != ''">
        <div class="col">
          <div class="row">
            <!-- ============= chart bar ===========  -->
            <div class="col-12 col-sm-1 col-md-12 col-lg-6">
              <!-- <p style="text-align: center;">Chart Customer</p> -->
              <div id="chart">
                <apexchart
                  type="bar"
                  height="350"
                  :options="chartOptions_bar_customer"
                  :series="series_bar_customer"
                ></apexchart>
              </div>
            </div>

            <!-- ============= chart pie ===========  -->
            <div class="col-12 col-sm-12 col-md-6 col-lg-3 table-responsive">
              <div id="chart" style="padding-top: 10%; padding-bottom: 10%;">
                <apexchart
                  type="pie"
                  width="380"
                  :options="chartOptions_pie_customer"
                  :series="series_pie_customer"
                ></apexchart>
              </div>
            </div>

            <!-- ============= Data table ===========  -->
            <div class="col-12 col-sm-12 col-md-6 col-lg-3">
              <div class="table-responsive my-4" style="padding-left: 10%; padding-right: 10%;">
                <table class="table-grid">
                  <thead style="text-align-last: left">
                    <th>{{ $t("message.days") }} </th>
                    <th>{{ $t("message.customer") }}</th>
                    <th>{{ $t("message.average") }}</th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{{$t('message.Sunday')}}</td>
                      <td>{{ Number(cus_sunday).toLocaleString() }}</td>
                      <td>{{ Number(cus_sunday / diffMonth).toLocaleString(undefined, {maximumFractionDigits: 2}) }}</td>
                    </tr>
                    <tr>
                      <td>{{$t('message.Monday')}}</td>
                      <td>{{ Number(cus_monday).toLocaleString() }}</td>
                      <td>{{ Number(cus_monday / diffMonth).toLocaleString(undefined, {maximumFractionDigits: 2}) }}</td>
                    </tr>
                    <tr>
                      <td>{{$t('message.Tuesday')}}</td>
                      <td>{{ Number(cus_tuesday).toLocaleString() }}</td>
                      <td>{{ Number(cus_tuesday / diffMonth).toLocaleString(undefined, {maximumFractionDigits: 2}) }}</td>
                    </tr>
                    <tr>
                      <td>{{$t('message.Wednesday')}}</td>
                      <td>{{ Number(cus_wednesday).toLocaleString() }}</td>
                      <td>{{ Number(cus_wednesday / diffMonth).toLocaleString(undefined, {maximumFractionDigits: 2}) }}</td>
                    </tr>
                    <tr>
                      <td>{{$t('message.Thursday')}}</td>
                      <td>{{ Number(cus_thursday).toLocaleString() }}</td>
                      <td>{{ Number(cus_thursday / diffMonth).toLocaleString(undefined, {maximumFractionDigits: 2}) }}</td> 
                    </tr>
                    <tr>
                      <td>{{$t('message.Friday')}}</td>
                      <td>{{ Number(cus_friday).toLocaleString() }}</td>
                      <td>{{ Number(cus_friday / diffMonth).toLocaleString(undefined, {maximumFractionDigits: 2}) }}</td>
                    </tr>
                    <tr>
                      <td>{{$t('message.Saturday')}}</td>
                      <td>{{ Number(cus_saturday).toLocaleString() }}</td>
                      <td>{{ Number(cus_saturday / diffMonth).toLocaleString(undefined, {maximumFractionDigits: 2}) }}</td>
                    </tr>
                    <tr>
                      <td></td>
                      <td>{{ Number(sum_cus).toLocaleString() }}</td>
                      <td>{{ Number(sum_cus / diffMonth).toLocaleString(undefined, {maximumFractionDigits: 2})}} </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
    </b-card>
  </div>
</template>
<script>
import webServices from "../../script";
import DateTimePicker from "../../components/DateTimePicker/MonthCustom";

import {
  MultiSelect,
  MultiListSelect
} from "../../custom_modules/search-select";
import moment from "moment";
import VueJsonPretty from "vue-json-pretty";

export default {
  name: "orders",
  components: {
    DateTimePicker,
    MultiSelect,
    MultiListSelect,
    VueJsonPretty
  },
  data() {
    return {
      api: {},
      timeSel: 0,
      timeOptTH: [
        { value: 0, text: '2022 ถึง ปีปัจจุบัน' },
        { value: 1, text: '<2022'}
      ],
      timeOptEN: [
        { value: 0, text: '2022 To This Year' },
        { value: 1, text: '<2022'}
      ],
      language: window.localStorage.getItem("language"),
      role_id: window.localStorage.getItem("roleID"),
      userBusiness: window.localStorage.getItem("business"),
      selectedProduct: [],
      selectedMachine: [],
      machineData: [],
      selectedId: {},
      searchData: {
        // datetime: ""
        datetime: [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
      },

      series_pie_customer: [],
      chartOptions_pie_customer: {
        colors: [
          "#E10000",
          "#F1C40F",
          "#DEA3BD",
          "#00AA80",
          "#FF9F00",
          "#5DADE2",
          "#AF7AC5"
        ],
        chart: {
          width: 380,
          type: "pie"
        },
        labels: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 400
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ],
        yaxis: {
          labels: {
            formatter: function (value) {
              return  Number(value).toLocaleString()
            }
          },
        },
      },

      series_bar_customer: [
        {
          name: "Customer",
          data: []
        }
      ],

      chartOptions_bar_customer: {
        chart: {
          type: "bar",
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
            endingShape: "rounded"
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"]
        },
        xaxis: {
          categories: []
        },
        yaxis: {
          // title: {
          //   text: '$ (thousands)'
          // }
          labels: {
            formatter: function (value) {
              return  Number(value).toLocaleString()
            }
          },
        },
        fill: {
          opacity: 1
        }
        // tooltip: {
        //   y: {
        //     formatter: function (val) {
        //       return + val + " person"
        //     }
        //   }
        // }
      },

      customerbyday: {},
      cus_sunday: 0,
      cus_monday: 0,
      cus_tuesday: 0,
      cus_wednesday: 0,
      cus_thursday: 0,
      cus_friday: 0,
      cus_saturday: 0,
      sum_cus: 0,

      total_sunday: 0,
      total_monday: 0,
      total_tuesday: 0,
      total_wednesday: 0,
      total_thursday: 0,
      total_friday: 0,
      total_saturday: 0,
      sum_total: 0,

      diffMonth: 0,
    };
  },
  async mounted() {
    await this.setParams(this.$route.query);
    await this.getMachine();

    if (
      !this.$isRole("admin", this.role_id) &&
      !this.$isRole("callcenter", this.role_id)
    ) {
      await this.getTransactions();
    }
  },
  methods: {
    selectTime(value) {
      if (value > 0) {
        this.api.defaults.baseURL = '/old/' + this.axios.defaults.baseURL
      } else {
        this.api.defaults.baseURL = this.axios.defaults.baseURL
      }
    },

    setParams(query) {
      if (Object.keys(query).length != 0) {
        // console.log(query)
        this.fromdate = query.from;
        this.todate = query.to;
        this.searchData.datetime = [query.from, query.to];
        this.searchData.productId = query.productId || "";
        this.searchData.machineId = query.machineId || "";
      }
    },

    getMachine() {
      this.$Progress.start();
      this.axios
        .get(`/machines/list`)
        .then(res => {
          this.$Progress.finish();
          this.machineData = res.data;
          if (this.machineData.length == 1) {
            this.selectedMachine = this.machineData;
          }
        })
        .catch(err => {
          this.$Progress.fail();
          this.$toast.error({
            title: "ERROR",
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText
            }
          });
          console.log("error @machine");
          console.log(err);
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
        });
    },

    showAlert_Warning() {
      if(window.localStorage.getItem("language") == 'th'){
        var title = "โปรดเลือกช่วงเวลาใหม่อีกครั้ง"
        var text = "ไม่สามารถเลือกเดือนได้มากกว่า 6 เดือน"
        var confirmButton = "ปิด"
      }else{
        var title = "Please select a new time again"
        var text = "Cannot select more than 6 months."
        var confirmButton = "Close"
      }

      this.$swal({
        title: title,
        text: text,
        icon: 'warning',
        // showCancelButton: true,
        confirmButtonColor: '#3085d6',
        // cancelButtonColor: '#d33',
        confirmButtonText: confirmButton
      })
    },

    monthDiff(d1, d2) {
      var months;
      months = (d2.getFullYear() - d1.getFullYear()) * 12;
      months -= d1.getMonth();
      months += d2.getMonth();
      return months <= 0 ? 0 : months;
    },

    async searchFn() {
      this.customerbyday = {};
      this.cus_sunday = 0;
      this.cus_monday = 0;
      this.cus_tuesday = 0;
      this.cus_wednesday = 0;
      this.cus_thursday = 0;
      this.cus_friday = 0;
      this.cus_saturday = 0;
      this.sum_cus = 0;

      this.total_sunday = 0;
      this.total_monday = 0;
      this.total_tuesday = 0;
      this.total_wednesday = 0;
      this.total_thursday = 0;
      this.total_friday = 0;
      this.total_saturday = 0;
      this.sum_total = 0;

      this.series_pie_customer = [];
      this.series_bar_customer[0].data = [];

      this.chartOptions_pie_customer.labels = [];
      this.chartOptions_bar_customer.xaxis.categories = [];

      var localStorageUser =  JSON.parse(window.localStorage.getItem("users") )
      var datetimeRequest = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')

      this.searchData.userName = localStorageUser.userName
      this.searchData.userRole = localStorageUser.role
      this.searchData.userType = localStorageUser.type
      this.searchData.userConfiglanguage = localStorageUser.config.language
      this.searchData.datetimeRequest = datetimeRequest

      var dateStart = new Date(this.searchData.datetime[0])
      var dateEnd = new Date(this.searchData.datetime[1])

      // console.log('monthDiff:', this.monthDiff(dateStart, dateEnd))
      if(this.monthDiff(dateStart, dateEnd) < 6){
        // console.log('เลือกสำเร็จ')
        await this.getCustomersByDay();
      }else{
        // console.log('กรุณาเลือใหม่')
        this.showAlert_Warning()
      }
    },

    async getCustomersByDay() {
      const params = {
        ...this.searchData,
        machine: this.selectedMachine,
        from: (this.searchData.from = moment(this.searchData.datetime[0])
          .startOf("month")
          .format("YYYY-MM-DD HH:mm:ss")),
        to: (this.searchData.to = moment(this.searchData.datetime[1])
          .endOf("month")
          .format("YYYY-MM-DD HH:mm:ss")),
        // type: "washing",
        // notThinkStatus: [
        //   "CANCEL_BY_MC_FAIL",
        //   "CANCEL_PAYMENT",
        //   "CANCEL_TIMEOUT"
        // ]
      };
      await this.axios
        .get(`/analyze/customerbyday`, { params })
        .then(res => {
          this.customerbyday = res.data.data;
          console.log("customerbyday", this.customerbyday);

          this.diffMonth = moment(this.searchData.datetime[1]).diff(moment(this.searchData.datetime[0]), 'months', true) + 1
          console.log('diffMonth',this.diffMonth)

          if (res.data) {
            for (var i = 0; i < this.customerbyday.length; i++) {
              if (this.customerbyday[i].dayname == "Sunday") {
                this.cus_sunday =
                  this.cus_sunday + this.customerbyday[i].customer;
                this.total_sunday =
                  this.total_sunday + this.customerbyday[i].total;
              }
              if (this.customerbyday[i].dayname == "Monday") {
                this.cus_monday =
                  this.cus_monday + this.customerbyday[i].customer;
                this.total_monday =
                  this.total_monday + this.customerbyday[i].total;
              }
              if (this.customerbyday[i].dayname == "Tuesday") {
                this.cus_tuesday =
                  this.cus_tuesday + this.customerbyday[i].customer;
                this.total_tuesday =
                  this.total_tuesday + this.customerbyday[i].total;
              }
              if (this.customerbyday[i].dayname == "Wednesday") {
                this.cus_wednesday =
                  this.cus_wednesday + this.customerbyday[i].customer;
                this.total_wednesday =
                  this.total_wednesday + this.customerbyday[i].total;
              }
              if (this.customerbyday[i].dayname == "Thursday") {
                this.cus_thursday =
                  this.cus_thursday + this.customerbyday[i].customer;
                this.total_thursday =
                  this.total_thursday + this.customerbyday[i].total;
              }
              if (this.customerbyday[i].dayname == "Friday") {
                this.cus_friday =
                  this.cus_friday + this.customerbyday[i].customer;
                this.total_friday =
                  this.total_friday + this.customerbyday[i].total;
              }
              if (this.customerbyday[i].dayname == "Saturday") {
                this.cus_saturday =
                  this.cus_saturday + this.customerbyday[i].customer;
                this.total_saturday =
                  this.total_saturday + this.customerbyday[i].total;
              }
            }
            this.sum_cus =
              this.cus_sunday +
              this.cus_monday +
              this.cus_tuesday +
              this.cus_wednesday +
              this.cus_thursday +
              this.cus_friday +
              this.cus_saturday;

            this.sum_total =
              this.total_sunday +
              this.total_monday +
              this.total_tuesday +
              this.total_wednesday +
              this.total_thursday +
              this.total_friday +
              this.total_saturday;

            // ============== Start Bar ==============
            if(window.localStorage.getItem("language") == 'th'){
              this.chartOptions_bar_customer.xaxis.categories.push("อาทิตย์");
              this.chartOptions_bar_customer.xaxis.categories.push("จันทร์");
              this.chartOptions_bar_customer.xaxis.categories.push("อังคาร");
              this.chartOptions_bar_customer.xaxis.categories.push("พุธ");
              this.chartOptions_bar_customer.xaxis.categories.push("พฤหัสบดี");
              this.chartOptions_bar_customer.xaxis.categories.push("ศุกร์");
              this.chartOptions_bar_customer.xaxis.categories.push("เสาร์");

            }else{
              this.chartOptions_bar_customer.xaxis.categories.push("Sunday");
              this.chartOptions_bar_customer.xaxis.categories.push("Monday");
              this.chartOptions_bar_customer.xaxis.categories.push("Tuesday");
              this.chartOptions_bar_customer.xaxis.categories.push("Wednesday");
              this.chartOptions_bar_customer.xaxis.categories.push("Thursday");
              this.chartOptions_bar_customer.xaxis.categories.push("Friday");
              this.chartOptions_bar_customer.xaxis.categories.push("Saturday");
            }

            this.series_bar_customer[0].data.push(this.cus_sunday);
            this.series_bar_customer[0].data.push(this.cus_monday);
            this.series_bar_customer[0].data.push(this.cus_tuesday);
            this.series_bar_customer[0].data.push(this.cus_wednesday);
            this.series_bar_customer[0].data.push(this.cus_thursday);
            this.series_bar_customer[0].data.push(this.cus_friday);
            this.series_bar_customer[0].data.push(this.cus_saturday);

            // ============== End Bar ==============
            // ============== Start Pie ==============
            if(window.localStorage.getItem("language") == 'th'){
              this.chartOptions_pie_customer.labels.push("อาทิตย์");
              this.chartOptions_pie_customer.labels.push("จันทร์");
              this.chartOptions_pie_customer.labels.push("อังคาร");
              this.chartOptions_pie_customer.labels.push("พุธ");
              this.chartOptions_pie_customer.labels.push("พฤหัสบดี");
              this.chartOptions_pie_customer.labels.push("ศุกร์");
              this.chartOptions_pie_customer.labels.push("เสาร์");

            }else{
              this.chartOptions_pie_customer.labels.push("Sunday");
              this.chartOptions_pie_customer.labels.push("Monday");
              this.chartOptions_pie_customer.labels.push("Tuesday");
              this.chartOptions_pie_customer.labels.push("Wednesday");
              this.chartOptions_pie_customer.labels.push("Thursday");
              this.chartOptions_pie_customer.labels.push("Friday");
              this.chartOptions_pie_customer.labels.push("Saturday");
            }


            this.series_pie_customer.push(this.cus_sunday);
            this.series_pie_customer.push(this.cus_monday);
            this.series_pie_customer.push(this.cus_tuesday);
            this.series_pie_customer.push(this.cus_wednesday);
            this.series_pie_customer.push(this.cus_thursday);
            this.series_pie_customer.push(this.cus_friday);
            this.series_pie_customer.push(this.cus_saturday);
            // ============== End pie ==============

          }

        })
        .catch(err => {
          this.$Progress.fail();
          console.log(err);
          this.$toast.error({
            title: "ERROR",
            message: err
          });
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
        });
    },

    onSelectMachine(items) {
      // console.log(items)
      this.selectedMachine = items;
      this.searchData.machineId = this.selectedMachine.map(
        data => data.machineId
      );
    },

    onSelect(items) {
      // console.log(items)
      this.selectedProduct = items;
      this.searchData.productId = this.selectedProduct.map(data => data.id);
    },

    changeTab(params) {
      this.rowData = {};
      this.totalRows = 0;
      this.totalSubRows = 0;
      this.summary = {};
      this.searchData.type = params;
      if (
        !this.$isRole("admin", this.role_id) &&
        !this.$isRole("callcenter", this.role_id)
      ) {
        this.searchFn();
      }
    }
  }
};
</script>
